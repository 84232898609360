import gql from 'graphql-tag'
import { useMutation } from 'react-apollo'

const ADD_LEAD = gql`
  mutation AddLead($data: LeadInput) {
    createLead(input: { data: $data }) {
      lead {
        id
      }
    }
  }
`

export const usePostLead = () => useMutation(ADD_LEAD)
