import React from 'react'
import { useFormik } from 'formik'

import { addMessage } from 'src/shared/features/snack'

import { formNames, VacancyForm, formatters } from 'src/shared/forms'
import { sendAnalyticsEvent } from 'src/shared/features/analytics'

import { breakpoints, useDimensions } from 'src/ui/dimensions'
import { useTheme, styled } from 'src/ui/theme'
import { HBox, VBox, Wrapper, Col } from 'src/ui/layout'
import { Input, InputWithUpload, Button, Checkbox } from 'src/ui/molecules'
import { Body, Header } from 'src/ui/typography'

import { validator } from './validator'
import { usePostLead } from 'src/entities/leads/hooks'

import { upload } from 'src/shared/upload/upload'

const Container = styled.div`
  background: ${({ theme }) => theme.colors.brand.primary};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gray.bg};
`

const Fields = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1200px;

  @media (max-width: ${breakpoints.tablet}px) {
    flex-flow: column;
  }
`

const Column = styled(Col)`
  width: 50%;
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`

const initialValues: VacancyForm = {
  firstName: '',
  secondName: '',
  email: '',
  phone: '',
  portfolioLink: '',
  resumeLink: '',
  bio: '',
}

export const Form: React.FC<{ title: string }> = ({ title }) => {
  const theme = useTheme()

  const [resume, setResume] = React.useState<File | null>(null)
  const [portfolio, setPortfolio] = React.useState<File | null>(null)

  const { isDesktop, isMobile } = useDimensions()

  const [addLead] = usePostLead()

  const [isLoading, setIsLoading] = React.useState(false)
  const [touchedForm, setTouchedForm] = React.useState(false)

  const formik = useFormik({
    initialValues: initialValues,
    validate: validator,
    onSubmit: (values) => {
      setIsLoading(true)

      const sendForm = (portfolioId?: string, resumeId?: string) => {
        delete values['agree']
        delete values['resumeFile']
        delete values['portfolioFile']

        Promise.all([
          addLead({
            variables: {
              data: {
                ...values,
                pageTitle: title,
                type: 'Отклик на вакансию',
                portfolio: portfolioId ? portfolioId : '',
                resume: resumeId ? resumeId : '',
              },
            },
          }),
          new Promise((resolve) => setTimeout(() => resolve(), 1000)),
        ])
          .then(() => {
            addMessage({
              text: 'Мы уже читаем ваше сообщение ;)',
              id: 'vacancyFormSuccess',
            })
            sendAnalyticsEvent('RESPOND_VACANCY_FINISH')
            formik.resetForm()
            setResume(null)
            setPortfolio(null)
            setIsLoading(false)
          })
          .catch(() => {
            addMessage({
              text: 'Что-то пошло не так. Попытайтесь позднее',
              id: 'vacancyFormError',
            })
            setIsLoading(false)
            sendAnalyticsEvent('RESPOND_VACANCY_FAILURE')
          })
      }

      const uploads = [
        {
          file: resume,
          prefix: 'resume',
        },
        {
          file: portfolio,
          prefix: 'portfolio',
        },
      ].filter((file) => file.file !== null)

      //@ts-ignore
      const uploadRes = upload(uploads)
      if (uploadRes) {
        uploadRes
          //@ts-ignore
          .then((response: Response) => response.json())
          .then((data?: { id: string; name: string }[]) => {
            const resumeId = data
              ? data.find((file) => file.name.indexOf('resume_') !== -1)?.id
              : ''
            const portfolioId = data
              ? data.find((file) => file.name.indexOf('portfolio_') !== -1)?.id
              : ''

            sendForm(portfolioId, resumeId)
          })
          .catch(() => {
            addMessage({
              text: 'Что-то пошло не так. Попытайтесь позднее',
              id: 'vacancyFormError',
            })
            setIsLoading(false)
          })
      } else {
        sendForm('', '')
      }
    },
  })

  return (
    <Container>
      <Wrapper>
        {isMobile ? <HBox height={32} /> : <HBox height={80} />}

        <Header
          color={theme.colors.black.primary}
          size={isMobile ? 'h4' : 'h2'}
        >
          Откликнуться на вакансию
        </Header>
        {isMobile ? <HBox height={32} /> : <HBox height={52} />}
        <Fields>
          <Column>
            <Input
              variant="light"
              title="Фамилия"
              name={formNames.vacancyForm.secondName}
              value={formik.values.secondName}
              placeholder={'Иванова'}
              onChange={formik.handleChange}
              error={formik.touched.secondName && formik.errors.secondName}
              disabled={isLoading}
              onBlur={(e) => {
                if (e.target.value && !touchedForm) {
                  sendAnalyticsEvent('RESPOND_VACANCY_START')
                  setTouchedForm(true)
                }
              }}
            />
            <HBox height={15} />
            <Input
              variant="light"
              title="Имя"
              name={formNames.vacancyForm.firstName}
              value={formik.values.firstName}
              placeholder={'Анастасия'}
              onChange={formik.handleChange}
              error={formik.touched.firstName && formik.errors.firstName}
              disabled={isLoading}
            />
            <HBox height={15} />
            <Input
              variant="light"
              title="Телефон"
              name={formNames.vacancyForm.phone}
              value={formik.values.phone}
              placeholder={'8 (901) 451 85 21'}
              onChange={(e) => {
                formik.setFieldValue(
                  formNames.vacancyForm.phone,
                  formatters.phone(e.target.value || '')
                )
              }}
              error={formik.touched.phone && formik.errors.phone}
              disabled={isLoading}
              type="tel"
            />
            <HBox height={15} />
            <Input
              variant="light"
              title="Email"
              name={formNames.vacancyForm.email}
              value={formik.values.email}
              placeholder={'email@mail.ru'}
              onChange={formik.handleChange}
              error={formik.touched.email && formik.errors.email}
              disabled={isLoading}
              type="email"
            />
          </Column>
          {isDesktop ? <VBox width={135} /> : <HBox height={36} />}

          <Column>
            <InputWithUpload
              caption="Портфолио"
              fileName={formNames.vacancyForm.portfolioFile}
              textName={formNames.vacancyForm.portfolioLink}
              textValue={formik.values.portfolioLink}
              onTextChange={formik.handleChange}
              placeholder={'Ссылка или презентация'}
              disabled={isLoading}
              onFileChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setPortfolio(e.target.files[0])
                }
              }}
              file={portfolio}
              onRemoveFile={() => {
                setPortfolio(null)
              }}
              accept={['.doc', '.docx', '.pdf', '.txt']}
            />
            <HBox height={15} />
            <InputWithUpload
              caption="Резюме"
              textName={formNames.vacancyForm.resumeLink}
              textValue={formik.values.resumeLink}
              fileName={formNames.vacancyForm.resumeFile}
              onTextChange={formik.handleChange}
              placeholder={'Ссылка или pdf'}
              disabled={isLoading}
              onFileChange={(e) => {
                if (e.target.files && e.target.files.length > 0) {
                  setResume(e.target.files[0])
                  formik.handleChange(e)
                }
              }}
              file={resume}
              onRemoveFile={() => {
                setResume(null)
                formik.setFieldValue(formNames.vacancyForm.resumeFile, null)
              }}
              accept={['.doc', '.docx', '.pdf', '.txt']}
              error={formik.touched.resumeLink && formik.errors.resumeLink}
            />
            <HBox height={15} />
            <Input
              variant="light"
              title="Сообщение"
              name={formNames.vacancyForm.bio}
              value={formik.values.bio}
              placeholder={'Пару слов о том, почему вы хотите работать у нас.'}
              onChange={formik.handleChange}
              multiline
              multilineHeight="173px"
              disabled={isLoading}
            />
          </Column>
        </Fields>
        {isMobile ? <HBox height={32} /> : <HBox height={80} />}
        <Button
          onClick={() => formik.handleSubmit()}
          variant="primary"
          loading={isLoading}
        >
          Отправить ответ
        </Button>
        <HBox />
        <HBox />
        <Checkbox
          color={theme.colors.black.primary}
          value={formik.values.agree}
          onPress={() => {
            formik.setFieldValue(
              formNames.contactForm.agree,
              !formik.values.agree
            )
          }}
          error={Boolean(formik.errors.agree)}
          label={
            <Body
              color={theme.colors.black.primary}
              size="secondary"
              linkUnderline
            >
              Согласен(-а) с{' '}
              <a
                target="_blank"
                color={theme.colors.black.primary}
                href="https://kode.ru/data-policy"
              >
                Политикой обработки персональных данных
              </a>{' '}
              и{' '}
              <a
                target="_blank"
                color={theme.colors.black.primary}
                href="https://kode.ru/data-agreement"
              >
                Согласием на обработку персональных данных
              </a>
            </Body>
          }
        />
        {isMobile ? <HBox height={32} /> : <HBox height={80} />}
      </Wrapper>
    </Container>
  )
}
