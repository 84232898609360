import React from 'react'
import ReactMarkdown from 'react-markdown'
import Img from 'gatsby-image'

import { breakpoints } from 'src/ui/dimensions'
import { styled } from 'src/ui/theme'
import { HBox, VBox, Wrapper, Col, Row } from 'src/ui/layout'
import { Label } from 'src/ui/atoms'
import { Button } from 'src/ui/molecules'
import { Markdown } from 'src/ui/typography'

import { Header as Title } from 'src/ui/typography'

import { Props } from './types'

const Container = styled.div`
  display: flex;

  flex-flow: row;
  justify-content: space-between;
  min-height: 495px;
`

const Content = styled(Col)`
  max-width: 600px;
`

const Image = styled(Img)`
  background-size: cover;
  background-position: 0 0;
  width: 645px;

  @media (max-width: ${breakpoints.tablet}px) {
    width: 300px;
  }
`

const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Header: React.FC<Props> = ({
  images,
  intro,
  conditions,
  title,
  onButtonPress,
}) => {
  return (
    <Wrapper>
      <Container>
        <Content>
          <HBox height={62} />
          <Title size="h2" as="h1">
            {title}
          </Title>
          <HBox height={40} />

          <Markdown>
            <ReactMarkdown source={intro} />
          </Markdown>

          <HBox height={24} />
          <Labels>
            {conditions.map((item) => (
              <>
                <Label>{item}</Label>
                <VBox />
              </>
            ))}
          </Labels>
          <HBox height={32} />
          <Row>
            <Button onClick={onButtonPress}>Откликнуться</Button>
          </Row>
        </Content>

        <VBox width={32} />

        <Image fluid={images.vacancy} />
      </Container>
    </Wrapper>
  )
}
