import React from 'react'
import ReactMarkdown from 'react-markdown'

import { styled } from 'src/ui/theme'
import { HBox, VBox, Row } from 'src/ui/layout'
import { Label } from 'src/ui/atoms'
import { Button } from 'src/ui/molecules'
import { Hero } from 'src/ui/organisms'
import { Markdown } from 'src/ui/typography'

import { Props } from './types'

const Labels = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Header: React.FC<Props> = ({
  images,
  intro,
  conditions,
  title,
  onButtonPress,
}) => {
  return (
    <Hero title={title} background={images.vacancy}>
      <HBox />
      <Markdown>
        <ReactMarkdown source={intro} />
      </Markdown>

      <HBox height={24} />
      <Labels>
        {conditions.map((item) => (
          <>
            <Label>{item}</Label>
            <VBox />
          </>
        ))}
      </Labels>
      <HBox height={32} />
      <Row>
        <Button onClick={onButtonPress}>Откликнуться</Button>
      </Row>
    </Hero>
  )
}
