import React from 'react'

import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'

//@ts-ignore
import constants from 'hr/constants'

import { RootLayout } from 'src/layouts'
import { Header, Form } from 'src/features/vacancy'
import { Meta } from 'src/features/seo'
import { Markdown } from 'src/ui/typography'
import { HBox, Wrapper, Row } from 'src/ui/layout'
import { Button } from 'src/ui/molecules'
import { useDimensions } from 'src/ui/dimensions'

const Vacancy: React.FC<{
  data: GatsbyTypes.VacancyDataQuery & GatsbyTypes.DataQuery
}> = ({ data }) => {
  const { isMobile } = useDimensions()

  const formRef = React.useRef<HTMLDivElement>(null)

  const onHeaderButtonClickHandler = () => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const images = data.allFile.edges.reduce<TImages>((acc, img) => {
    return {
      ...acc,
      [img.node.name]: img.node.childImageSharp?.fluid as TFluidObject,
    }
  }, {})

  if (data && data.strapi && data.strapi.vacancies) {
    const vacancy = data.strapi.vacancies[0]
    return vacancy ? (
      <RootLayout>
        <Meta
          title={`Работа ${vacancy.title} в ${vacancy.regions
            ?.map((v) => v?.name)
            ?.join(', ')} — KODE`}
          description={`Ищете работу в IT? Компания KODE приглашает тебя стать частью дружной команды ✔ Ищем ${vacancy.title} ✔ Офис в Калининграде`}
        />
        {isMobile ? <HBox /> : <HBox height={44} />}

        <Header
          title={vacancy.title}
          conditions={
            vacancy.conditions
              ? vacancy.conditions.map((item) => item?.name || '')
              : []
          }
          intro={vacancy.leadText}
          images={images}
          onButtonPress={onHeaderButtonClickHandler}
        />
        {isMobile ? <HBox height={80} /> : <HBox height={96} />}

        <Wrapper>
          <Markdown>
            <ReactMarkdown source={vacancy.body} />
          </Markdown>
          <HBox height={48} />
          <Row>
            <Button onClick={onHeaderButtonClickHandler}>Откликнуться</Button>
          </Row>
        </Wrapper>
        <HBox height={80} />
        <div ref={formRef}>
          <Form title={vacancy.title} />
        </div>
        <HBox height={150} />
      </RootLayout>
    ) : null
  }
  return null
}

export default Vacancy

export const query = graphql`
  query VacancyData($slug: String!) {
    allFile(filter: { relativePath: { regex: "/career/i" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 1900) {
              srcSet
              base64
              src
              aspectRatio
            }
          }
        }
      }
    }
    strapi {
      vacancies(where: { slug: $slug, status: "publish" }) {
        id
        title
        slug
        excerpt
        body
        leadText
        regions {
          name
          id
        }
        conditions {
          id
          name
        }
        department {
          DesktopCover {
            url
          }
          MobileCover {
            url
          }
        }
      }
    }
  }
`
