import { regExp, VacancyForm } from 'src/shared/forms'

export const validator = (values: VacancyForm) => {
  const errors: VacancyForm = {}

  if (!values.email) {
    errors.email = 'Поле обязательно к заполнению'
  } else if (!regExp.email.test(values.email)) {
    errors.email = 'Некорректный e-mail'
  }

  if (!values.phone) {
    errors.phone = 'Поле обязательно к заполнению'
  }

  if (!values.firstName || values.firstName?.length < 2) {
    errors.firstName = 'Поле обязательно к заполнению'
  }
  if (!values.secondName || values.secondName?.length < 2) {
    errors.secondName = 'Поле обязательно к заполнению'
  }

  if (!values.agree) {
    errors.agree = true
  }

  if (!values.resumeFile && !values.resumeLink) {
    errors.resumeLink = 'Поле обязательно к заполнению'
  }

  if (values.resumeLink && !regExp.link.test(values.resumeLink)) {
    errors.resumeLink = 'Поле обязательно к заполнению'
  }

  return errors
}
